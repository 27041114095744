// -- Helpers BEGIN
const documentReady = require("%helpers%/document-ready.js");
// -- Helpers END


// -- Libraries BEGIN
// -- Libraries END


documentReady(function() {
	const $tabs = $(".tab-btn");
	const $tabsContent = $(".tab-content");
	const secClasses = [];

	const initTabs = () => {
		$tabs.on("click", function () {
			if (!$(this).hasClass("tab_cur")) {
				const key = $(this).attr("data-tab-key");

				$(this)
					.closest(".block-tabs")
					.find(".tab-btn, .tab-content")
					.removeClass("tab-btn_cur tab-content_cur");
				$(this)
					.addClass("tab-btn_cur")
					.closest(".block-tabs")
					.find(`div[data-tab-key=${ key }]`)
					.addClass("tab-content_cur");

                const secClass = $(this).attr("data-sec-class");
                const sec = $(this).closest(".sec");

                if (secClass) {
                    secClasses.push(secClass);
                    sec.addClass(secClass);
                } else if (secClasses.length) {
                    sec.removeClass(...secClasses);
                }
			}
		});
	};

	if($tabs.length && $tabsContent.length) initTabs();

});
