const ready = require("%helpers%/document-ready.js");

ready(function() {

	const $btns = $("a[href^='#']");
	const headerHeight = $(".header").outerHeight(true);
	const $menu = $(".navbar");
	const $menuBtn = $(".header__btn");
	const $page = $("html, body");

	const hidemenu = () => {
		$menuBtn.removeClass("header__btn_active");
		$menu.removeClass("navbar_visible");
		$page.removeClass("page-fixed");
	};

	// -- Functions BEGIN
	const addEventButtons = () => {
		$btns.on("click", function(e) {
			e.preventDefault();

			hidemenu();

			const blockKey = $(this).attr("href");
			const $block = $(blockKey);

			if ( $block.length ) {
				const blockOffset = $block.offset().top;
				const offsetTop = ($(window).width() > 992) ? 30 : 0;
				const scrollTop = blockOffset - headerHeight + offsetTop + 54;

				$([document.documentElement, document.body]).animate({
					scrollTop 
				}, 1000);
			}
		});
	};
	// -- Functions END

	if ( $btns.length ) addEventButtons();
});