// -- Helpers BEGIN
const documentReady = require("%helpers%/document-ready.js");
// -- Helpers END


// -- Libraries BEGIN
// -- Libraries END


documentReady(function() {

    const productsStore = [
        {
            id: 1,
            img: "img/prod_1.png",
            name: "Снежок",
            price: 450,
            material: "Металл",
            sizes: "1660*790*1700",
            size: "70х160",
            desc: "Реечное основание под матрас, дымоход (труба), защитные съемные бортики. Выдерживает до 150-180кг веса на основание и до 80 кг на перекладинах и бортиках",
        },
        {
            id: 2,
            img: "img/prod_2.png",
            name: "Ромашка",
            price: 450,
            material: "Металл",
            sizes: "1660*790*1700",
            size: "70х160",
            desc: "Реечное основание под матрас, дымоход (труба), защитные съемные бортики. Выдерживает до 150-180кг веса на основание и до 80 кг на перекладинах и бортиках",
        },
        {
            id: 3,
            img: "img/prod_3.png",
            name: "Облако",
            price: 450,
            material: "Металл",
            sizes: "1660*790*1700",
            size: "70х160",
            desc: "Реечное основание под матрас, дымоход (труба), защитные съемные бортики. Выдерживает до 150-180кг веса на основание и до 80 кг на перекладинах и бортиках",
        },
        {
            id: 4,
            img: "img/prod_4.png",
            name: "Чайка",
            price: 450,
            material: "Металл",
            sizes: "1660*790*1700",
            size: "70х160",
            desc: "Реечное основание под матрас, дымоход (труба), защитные съемные бортики. Выдерживает до 150-180кг веса на основание и до 80 кг на перекладинах и бортиках",
        },
        {
            id: 5,
            img: "img/prod_5.png",
            name: "Апельсин",
            price: 450,
            material: "Металл",
            sizes: "1660*790*1700",
            size: "70х160",
            desc: "Реечное основание под матрас, дымоход (труба), защитные съемные бортики. Выдерживает до 150-180кг веса на основание и до 80 кг на перекладинах и бортиках",
        },
        {
            id: 6,
            img: "img/prod_6.png",
            name: "Черника",
            price: 450,
            material: "Металл",
            sizes: "1660*790*1700",
            size: "70х160",
            desc: "Реечное основание под матрас, дымоход (труба), защитные съемные бортики. Выдерживает до 150-180кг веса на основание и до 80 кг на перекладинах и бортиках",
        },
        {
            id: 7,
            img: "img/prod_7.png",
            name: "Бирюза",
            price: 450,
            material: "Металл",
            sizes: "1660*790*1700",
            size: "70х160",
            desc: "Реечное основание под матрас, дымоход (труба), защитные съемные бортики. Выдерживает до 150-180кг веса на основание и до 80 кг на перекладинах и бортиках",
        },
        {
            id: 8,
            img: "img/case_4.png",
            name: "Комплект с окном",
            price: 333,
            material: null,
            sizes: null,
            size: null,
            desc: null,
        },
        {
            id: 9,
            img: "img/case_5.png",
            name: "Комплект без окна",
            price: 307,
            material: null,
            sizes: null,
            size: null,
            desc: null,
        },
        {
            id: 10,
            img: "img/case_1.png",
            name: "Занавеска с окном",
            price: 120,
            material: null,
            sizes: null,
            size: null,
            desc: null,
        },
        {
            id: 11,
            img: "img/case_2.png",
            name: "Занавеска без окна",
            price: 94,
            material: null,
            sizes: null,
            size: null,
            desc: null,
        },
        {
            id: 12,
            img: "img/case_3.png",
            name: "Карман (1 шт.)",
            price: 17.5,
            material: null,
            sizes: null,
            size: null,
            desc: null,
        }
    ];


    let cart = new Vue({
        el: '#cart',

        data: {
            resultProducts: [],
            productCount: 0,
        },

        watch: {
            resultProducts: {
                handler: function (arr) {
                    let result = 0;

                    arr.forEach(function (item) {
                        result += item.count;
                    });
                    const cartCountEls = document.querySelectorAll(".cart__count");

                    cartCountEls.forEach(function (item) {
                        item.innerHTML = result;
                    });
                },
                deep: true
            }
        },

        methods: {
            getProductFromStore(id) {
                return productsStore.find(item => item.id === id);
            },

            getReactiveProduct(id) {
                return this.resultProducts.find(item => item.id === id);
            },

            removeProduct(id) {
                this.resultProducts.forEach(function (item, index, arr) {
                    if (item.id === id) arr.splice(index, 1);
                });
            },

            addProduct(id) {
                let product = this.getProductFromStore(id);

                if (!this.getReactiveProduct(id)) {
                    for(let key in product) {
                        this.$set(product, key, product[key]);
                        this.$set(product, "count", 1);
                    }

                    this.resultProducts.push(product);
                }
                else this.moreProduct(id);
            },

            moreProduct(id) {
                this.getReactiveProduct(id).count++;
            },

            lessProduct(id) {
                if (this.getReactiveProduct(id).count > 1) {
                    this.getReactiveProduct(id).count--;
                }
            }
        },

        computed: {
            totalPrice() {
                return this.resultProducts.reduce(function (sum, cur) {
                   return sum + (cur.count * cur.price);
                }, 0);
            }
        }
    });


    const $productBtns = $(".products__list-item-cart");

    $productBtns.on("click", function (e) {
        e.preventDefault();
        const productId = $(this).closest(".products__list-item").data("id");
        cart.addProduct(productId);
    });


});
