const ready = require("%helpers%/document-ready.js");

ready(function() {
	const $btnsShowPopups = $(".modal-btn");
	const $elementsHidePopups = $(".close-modal");
	const $allPopups = $(".popup");
	const $page = $("html, body");

	const $btn = $(".btn-products");
	const $btnFirstText = $btn.data("text-first");
	const $btnSecondText = $btn.data("text-second");


	$btn.on("click", function () {
		const $btnIdText = $(this).data('textId');
		const $listText = $(`.product__info[data-text-key=${$btnIdText}]`);
		const curText = $(this).text();

		if (curText === $btnFirstText) {
			$(this).text($btnSecondText);
			$listText.show('slow');
		}
		else if (curText === $btnSecondText) {
			$(this).text($btnFirstText);
			$listText.hide('slow');
		}
	});

	let $curPopup;
	let $curBtn;

	// -- Functions BEGIN
	const showPopup = () => {
		const key = $curBtn.attr("data-popup-key");
		$curPopup = $(`.popup[data-popup-key=${key}]`);

		if ( $curPopup.length ) {
			$allPopups.removeClass("popup_visible");
			$curPopup.addClass("popup_visible disabled");
			$page.addClass("page-fixed");

			setTimeout(function() {
				$curPopup.removeClass("disabled");
			}, 800); // -- After popups show animation show
		}
	};

	const hidePopups = () => {
		$allPopups.removeClass("popup_visible");
		$page.toggleClass("page-fixed");
	};
	// -- Functions END


	// -- Show popop BEGIN
	if ($btnsShowPopups.length && $allPopups.length) {
		$btnsShowPopups.on("click", function (e) {
			e.preventDefault();
			$curBtn = $(this);

			showPopup();
		});
	}
	// -- Show popop END


	// -- Hide popups BEGIN
	if ($elementsHidePopups.length && $allPopups.length) {
		$elementsHidePopups.on("click", function () {
			hidePopups();
		});
	}
	// -- Hide popups END


	$('.btn-callback').click(function (event) {
		event.preventDefault();
		if ($(".form-callback").find('input[name="name"]').val() == '' || $(".form-callback").find('input[name="phone"]').val() == '') {
			$('.text-error-callback').show();
			return false;
		}

		$('.text-error-callback').hide();

		$.ajax({
			type: "POST",
			url: "mail-callback.php",
			data: $(".form-callback").serialize(),
			success: function(data){
				$('.sended-form-cb').click();
				$(".form-callback").find('input[name="name"]').val('');
				$(".form-callback").find('input[name="phone"]').val('');
			},
			error: function(){
				console.log('error')
			}
		});
	});

	$('.btn-top').click(function (event) {
		$('html, body').animate({scrollTop: 0}, 600);
		return false;
	});



	$('.btn-write-us').click(function (event) {
		event.preventDefault();
		if ($(".form-write-us").find('input[name="name"]').val() == ''
			|| $(".form-write-us").find('input[name="mail"]').val() == ''
			|| $(".form-write-us").find('input[name="phone"]').val() == '') {
			$('.text-error-write-us').show();
			return false;
		}

		$('.text-error-write-us').hide();

		$.ajax({
			type: "POST",
			url: "mail-write-us.php",
			data: $(".form-write-us").serialize(),
			success: function(data){
				$('.sended-form-cb').click();
				console.log(1);
				$(".form-write-us").find('input[name="name"]').val('');
				$(".form-write-us").find('input[name="mail"]').val('');
				$(".form-write-us").find('input[name="phone"]').val('');
				$(".form-write-us").find('textarea[name="message"]').val('');
			},
			error: function(){
				console.log('error')
			}
		});
	});

	$('.btn_questions-2').click(function (event) {
		event.preventDefault();
		if ($(".form_questions-2").find('input[name="name"]').val() == ''
			|| $(".form_questions-2").find('input[name="mail"]').val() == ''
			|| $(".form_questions-2").find('input[name="phone"]').val() == '') {
			$('.text-error_questions-2').show();
			return false;
		}

		$('.text-error_questions-2').hide();

		$.ajax({
			type: "POST",
			url: "mail-write-us.php",
			data: $(".form_questions-2").serialize(),
			success: function(data){
				$('.sended-form-cb').click();
				$(".form_questions-2").find('input[name="name"]').val('');
				$(".form_questions-2").find('input[name="mail"]').val('');
				$(".form_questions-2").find('input[name="phone"]').val('');
				$(".form_questions-2").find('textarea[name="message"]').val('');
			},
			error: function(){
				console.log('error')
			}
		});
	});

	$('.btn_questions-1').click(function (event) {
		event.preventDefault();
		if ($(".form_questions-1").find('input[name="name"]').val() == ''
			|| $(".form_questions-1").find('input[name="mail"]').val() == ''
			|| $(".form_questions-1").find('input[name="phone"]').val() == '') {
			$('.text-error_questions-1').show();
			return false;
		}

		$('.text-error_questions-1').hide();

		$.ajax({
			type: "POST",
			url: "mail-write-us.php",
			data: $(".form_questions-1").serialize(),
			success: function(data){
				$('.sended-form-cb').click();
				$(".form_questions-1").find('input[name="name"]').val('');
				$(".form_questions-1").find('input[name="mail"]').val('');
				$(".form_questions-1").find('input[name="phone"]').val('');
				$(".form_questions-1").find('textarea[name="message"]').val('');
			},
			error: function(){
				console.log('error')
			}
		});
	});

	$('.btn-cart').click(function (event) {

		let cart = [];

		for (let $cartKey in $("#cart li.block-mobile-hide .cart__content-item-name")) {

			if($("#cart li.block-mobile-hide .cart__content-item-name").hasOwnProperty($cartKey)) {
				let title = $("#cart li.block-mobile-hide .cart__content-item-name")[$cartKey].innerHTML;
				let count = $("#cart li.block-mobile-hide .cart__content-item-counter-result")[$cartKey].innerHTML;
				let price = $("#cart li.block-mobile-hide .price")[$cartKey].innerHTML;

				if (title !== undefined) {
					cart.push({
						title: title,
						count: count,
						price: price
					})
				}
			}
		}

		event.preventDefault();
		if ($(".form-cart").find('input[name="name"]').val() == ''
			|| $(".form-cart").find('input[name="mail"]').val() == ''
			|| $(".form-cart").find('input[name="phone"]').val() == ''
			|| $(".form-cart").find('input[name="city"]').val() == ''
			|| $(".form-cart").find('input[name="address"]').val() == '') {
			$('.text-error_cart').show();
			return false;
		}

		$('.text-error_cart').hide();

		$.ajax({
			type: "POST",
			url: "mail-checkout.php",
			data: $(".form-cart").serialize() + '&cart=' + JSON.stringify(cart),
			success: function(data){
				$('.sended-form-checkout').click();
				$(".form-cart").find('input[name="name"]').val('');
				$(".form-cart").find('input[name="mail"]').val('');
				$(".form-cart").find('input[name="phone"]').val('');
				$(".form-cart").find('input[name="city"]').val('');
				$(".form-cart").find('input[name="address"]').val('');
				$(".form-cart").find('textarea[name="message"]').val('');
			},
			error: function(){
				console.log('error')
			}
		});
	});
});