// -- Helpers BEGIN
const documentReady = require("%helpers%/document-ready.js");
const breakpoints = require("%helpers%/breakpoints.js");
// -- Helpers END

// -- Libraries BEGIN
import Swiper from "swiper";
// -- Libraries END

// -- Libraries BEGIN
// -- Libraries END


documentReady(function() {
	const $slider = $(".slider .swiper-container");
	const $btn = $(".products__btn");
	const $btnFirstText = $btn.data("text-first");
	const $btnSecondText = $btn.data("text-second");
	const $products = $(".products .products__list-item");

	const windowW = $(window).width();
	const productCount = windowW > breakpoints.xl ? 4 :
		(windowW > breakpoints.lg && windowW < breakpoints.xl) ? 3 :
			(windowW > breakpoints.md && windowW < breakpoints.lg) ? 2 : 2;

	// -- Functions BEGIN
	const showFirstsItems = () => {
		$products.each(function (count) {
			if (count < productCount) $(this).show();
		});
	};

	if (($(document).width() > 575)) {
		$products.hide();
	}

	const initHideEvent = () => {
		showFirstsItems();

		$btn.on("click", function () {
			const curText = $(this).text();

			if (curText === $btnFirstText) {
				$(this).text($btnSecondText);
				$products.show('slow');
			}
			else if (curText === $btnSecondText) {
				$(this).text($btnFirstText);
				$products.hide();
				showFirstsItems();
			}
		});
	};
	// -- Functions END


	if ($btn.length && $products.length) initHideEvent();

	// -- Functions BEGIN
	const initSlider = () => {
		const sliderOptions = {
			speed: 800,
			loop: false,
			slidesPerView: 1
		};

		const initSwiper = new Swiper($slider, sliderOptions);
	};
	// -- Functions END

	if ($slider.length) initSlider();
});
