// -- Helpers BEGIN
import Swiper from "swiper";

const documentReady = require("%helpers%/document-ready.js");
// -- Helpers END


// -- Libraries BEGIN
// -- Libraries END


documentReady(function() {
	const $btn = $(".light__btn");
	const $btnFirstText = $btn.data("text-first");
	const $btnSecondText = $btn.data("text-second");
	const $slider = $(".light__slider");

	// -- Functions BEGIN
	const initEvent = () => {
		const sliderOptions = {
			speed: 600,
			loop: false,
			slidesPerView: 1,
			pagination: {
				el: ".swiper-pagination",
				type: "bullets",
				clickable: true
			},
		};

		let initSwiper;

		function initSlider() {
			initSwiper = new Swiper($slider, sliderOptions);
		}

		$btn.on("click", function () {
			const curText = $(this).text();
			$(".light__img, .light__slider").removeClass("active");

			if (curText === $btnFirstText) {
				$(this).text($btnSecondText);
				$(".light__slider").addClass("active");
				$(this).closest(".sec").addClass("sec_dark");
			}
			else if (curText === $btnSecondText) {
				$(this).text($btnFirstText);
				$(this).closest(".sec").removeClass("sec_dark");
				$(".light__img").addClass("active");
			}

			// $(".banner__video").removeClass("active");
			// $(`.banner__video.${$(this).data("cur-video")}`).addClass("active");
		});

		if ($slider.length) initSlider();

	};
	// -- Functions END


	if ($btn.length) initEvent();
});
