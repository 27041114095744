// -- Helpers BEGIN
const documentReady = require("%helpers%/document-ready.js");
// -- Helpers END


// -- Libraries BEGIN
// -- Libraries END


documentReady(function() {
	const $btn = $(".banner__btn");
	const $btnFirstText = $btn.data("text-first");
	const $btnSecondText = $btn.data("text-second");

	// -- Functions BEGIN
	const changeVideos = () => {
		$btn.on("click", function () {
		    const curText = $(this).text();

			if (curText === $btnFirstText) {
				$(this).text($btnSecondText);
				$(this).data("cur-video", "second");
			}
			else if (curText === $btnSecondText) {
				$(this).text($btnFirstText);
				$(this).data("cur-video", "first");
			}

			$(".banner__video").removeClass("active");
			$(`.banner__video.${$(this).data("cur-video")}`).addClass("active");
		});
	};
	// -- Functions END


	if ($btn.length) changeVideos();
});
