// -- Helpers BEGIN
const documentReady = require("%helpers%/document-ready.js");
// -- Helpers END


// -- Libraries BEGIN
import Swiper from "swiper";
// -- Libraries END


documentReady(function() {
	const $slider = $(".slider .swiper-container.container-desc");
	const $sliderBaby = $(".slider .swiper-container.slider__baby");
	const $btnPrev = $(".slider .btn-default-prev-desc");
	const $btnPrevBaby = $(".slider .slider__baby .slider__btn_prev-baby");
	const $btnNext = $(".slider .btn-default-next-desc");
	const $btnNextBaby = $(".slider .slider__baby .slider__btn_next-baby");

	// -- Functions BEGIN
	const initSlider = () => {
	    const sliderOptions = {
			speed: 800,
			loop: false,
			slidesPerView: 1,
			navigation: {
				prevEl: $btnPrev,
				nextEl: $btnNext,
			},
		};

		const initSwiper = new Swiper($slider, sliderOptions);
		const initSwiperBaby = new Swiper($sliderBaby, sliderOptions);
	};

	const initSwiperBaby = () => {
		const sliderOptions = {
			speed: 800,
			loop: false,
			slidesPerView: 1,
			navigation: {
				prevEl: $btnPrevBaby,
				nextEl: $btnNextBaby,
			},
		};

		const initSwiper = new Swiper($slider, sliderOptions);
		const initSwiperBaby = new Swiper($sliderBaby, sliderOptions);
	};
	// -- Functions END

	if ($slider.length) initSlider();
	if ($sliderBaby.length) initSwiperBaby();

	$btnNext.click(function () {
		if ($('.slider-arrow-desc.swiper-slide-prev').length > 0) {
			$('.btn-default-prev-desc').show();
			$('.btn-default-prev-desc').css("opacity", "1");
			$btnNext.text('Вперед');
		}

		if ($('.slider-arrow-desc.swiper-slide-next').length === 0) {
			$('.btn-default-next-desc').hide();
		}
	});

	$btnPrev.click(function () {
		if ($('.slider-arrow-desc.swiper-slide-prev').length === 0) {
			$('.btn-default-prev-desc').hide();
			$btnNext.text('Смотреть дальше');
		}

		if ($('.slider-arrow-desc.swiper-slide-next').length > 0) {
			$('.btn-default-next-desc').show();
		}
	});

	$slider.on('touchmove', function () {
		setTimeout(function () {
			if ($('.slider-arrow-desc.swiper-slide-prev').length > 0) {
				$('.btn-default-prev-desc').show();
				$('.-mobile .btn-default-prev-desc').css("opacity", "1");
			}

			if ($('.slider-arrow-desc.swiper-slide-next').length === 0) {
				$('.btn-default-next-desc').hide();
			}

			if ($('.slider-arrow-desc.swiper-slide-prev').length === 0) {
				$('.btn-default-prev-desc').hide();
				$('.-mobile .btn-default-prev-desc').css("opacity", "0");
			}

			if ($('.slider-arrow-desc.swiper-slide-next').length > 0) {
				$('.btn-default-next-desc').show();
			}
		}, 500)
	});

	$btnNextBaby.click(function () {
		if ($('.slider-arrow-mobile.swiper-slide-prev').length > 0) {
			$('.btn-default-prev-mobile').show();
			$('.btn-default-prev-mobile').css("opacity", "1");
		}

		if ($('.slider-arrow-mobile.swiper-slide-next').length === 0) {
			$('.btn-default-next-mobile').hide();
		}

		this.allowSlideNext = 1;
	});

	$btnPrevBaby.click(function () {
		if ($('.slider-arrow-mobile.swiper-slide-prev').length === 0) {
			$('.btn-default-prev-mobile').hide();
			$('.btn-default-prev-mobile').css("opacity", "0");
		}

		if ($('.slider-arrow-mobile.swiper-slide-next').length > 0) {
			$('.btn-default-next-mobile').show();
		}
	});

	$sliderBaby.on('touchmove', function () {
		setTimeout(function () {
			if ($('.slider-arrow-mobile.swiper-slide-prev').length > 0) {
				$('.btn-default-prev-mobile').show();
				$('.-mobile .btn-default-prev-mobile').css("opacity", "1");
			}

			if ($('.slider-arrow-mobile.swiper-slide-next').length === 0) {
				$('.btn-default-next-mobile').hide();
			}

			if ($('.slider-arrow-mobile.swiper-slide-prev').length === 0) {
				$('.btn-default-prev-mobile').hide();
				$('.-mobile .btn-default-prev-mobile').css("opacity", "0");
			}

			if ($('.slider-arrow-mobile.swiper-slide-next').length > 0) {
				$('.btn-default-next-mobile').show();
			}
		}, 500)
	});

	if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )){
	} else {
		$(".slider__item-text").addClass('position-relative');
	}
});
