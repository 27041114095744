// -- Helpers BEGIN
const documentReady = require("%helpers%/document-ready.js");
// -- Helpers END


// -- Libraries BEGIN
// -- Libraries END


documentReady(function() {
    const forms = $(".form");

    const sendMessage = ( data ) => {
       /* $.ajax({
            url: "mail.php",
            type: "POST",
            data: data,
            success: function (response) {
                console.log(response);
            },
            error: function (response) {
                console.log(response);
            }
        });*/
    };

    forms.submit(function (e) {
        e.preventDefault();

        sendMessage($(this).serialize());
    });

});
